import {
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  UserCredential,
} from "firebase/auth";

import { createContext, useState, useEffect, useContext } from "react";
import { User } from "@firebase/auth-types";

import { auth, db } from "./firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

type AuthContextType = {
  currentUser: User | null;
  userData: any;
  login?: () => Promise<UserCredential>;
  logout?: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userData: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

type Props = {
  children?: JSX.Element;
};

const AuthProvider = ({ children }: Props): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<any | null>(null);
  //const [isLogin, setIsLogin] = useState(true);

  const login = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    return onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        setCurrentUser(user);

        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setUserData(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      } else {
        setCurrentUser(null);
        setUserData(null);
      }
    });
  }, []);

  const value: AuthContextType = {
    currentUser,
    userData,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
