export const CustomJa = {
    "MY_INPUT_OUTPUT" : "入出力",
    "MY_LOGIC": "条件分岐",
    "MY_LOOPS": "ループ",
    "MY_TEXT": "テキスト",
    "MY_VARIABLES": "変数",
    "MY_LISTS": "リスト",
    "MY_FUNCTIONS": "関数",
    "MY_OPERATORS": "数・演算",
    "MY_INOUT_HUE": "30",
  }