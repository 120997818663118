import React, { useEffect } from 'react';

interface Props {
// Propsの型を定義する
}

declare global {
    interface Window {
      adsbygoogle?: unknown[];
    }
  }

const AdsCard: React.FC<Props> = (props: Props) => {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);
    return (
        <ins className="adsbygoogle"
            style={{ display: 'inline-block', width: '100%', height: '100%'  }}
            data-ad-client="ca-pub-3524335930996757"
            data-ad-slot="1152284661"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        );
    };

export default AdsCard;