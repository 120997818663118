import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import CardComponent from './CardComponent';
import Grid from '@mui/material/Grid'; // Grid version 1
import {useAuth} from '../../../components/firebase/authContext';
import { createContext, useState, useEffect, useContext } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  
  const {userData} = useAuth();

  useEffect(() => {
    // userData が変更されたときに実行する処理を書く
    // 例: DOMを更新する
    console.log("userData changed:", userData);
  }, [userData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Card sx={{ minWidth: 275, maxWidth: 800, width:"95%", minHeight:600, mt:1, mb:1}}>
        <Box sx={{ width: "100%", bgcolor: "#2A4988", p: 1}}>
          <Typography align='center' variant="h6" color="white">
            ミッションリスト
          </Typography>
        </Box>
        <Box sx={{ width: "100%", borderBottom: 1, bgcolor: '#fff'}}>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary"
            textColor="primary" aria-label="basic tabs example">
            <Tab label="ビギナー" style={{ color:value === 0 ? "white" : "blue", backgroundColor: value === 0 ? "#3A60B2" : "white"}}  {...a11yProps(0)} />
            <Tab label="ブロンズ" style={{ color:value === 1 ? "white" : "blue", backgroundColor: value === 1 ? "#3A60B2" : "white"}}  {...a11yProps(1)} />
            <Tab label="シルバー" style={{ color:value === 2 ? "white" : "blue", backgroundColor: value === 2 ? "#3A60B2" : "white"}}  {...a11yProps(2)} />
            <Tab label="ゴールド" style={{ color:value === 3 ? "white" : "blue", backgroundColor: value === 3 ? "#3A60B2" : "white"}}  {...a11yProps(3)} />
          </Tabs>
        </Box>
          <TabPanel value={value} index={0}>
            <CardComponent link="/b001" detail='数の表示' clearFlag={userData?.achievement?.["B001"] ?? false} ></CardComponent>
            <CardComponent link="/b002" detail='文字の表示' clearFlag={userData?.achievement?.["B002"] ?? false} ></CardComponent>
            <CardComponent link="/b003" detail='繰り返しを使った数字の表示' clearFlag={userData?.achievement?.["B003"] ?? false} ></CardComponent>
            <CardComponent link="/b004" detail='繰り返しと変数を使った数字の表示' clearFlag={userData?.achievement?.["B004"] ?? false} ></CardComponent>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CardComponent link="/br001" detail='偶数を表示しよう' clearFlag={userData?.achievement?.["Br001"] ?? false} ></CardComponent>
          </TabPanel>
          <TabPanel value={value} index={2}>
          <CardComponent link="/s001" detail='1000の約数を求めよう' clearFlag={userData?.achievement?.["S001"] ?? false} ></CardComponent>
          </TabPanel>
          <TabPanel value={value} index={3}>
          <CardComponent link="/g001" detail='100までの素数を求めよう' clearFlag={userData?.achievement?.["G001"] ?? false} ></CardComponent>
          </TabPanel>
      </Card>
    </Grid>
  );
}