import * as React from 'react';
import { useEffect } from 'react';
import Blockly from "blockly";
import { useState, useRef, useContext } from 'react';
import "../../blocks/myblocks"
import {javascriptGenerator} from 'blockly/javascript';
import { DisableTopBlocks } from '../../blocks/option'
import * as Ja from 'blockly/msg/ja'
import { CustomJa } from '../../blocks/custom_ts';
import {CodeContext} from '../../App';
import "../../blocks/prompt"

interface Options { 
    obj: {
        [test: string]: any //anyをつけると、後からオブジェクトの追加ができる。
    }
};

export default function BlocklyComponent(props: Options) {
    //let primaryWorkspace:any = useRef();

    const generateCode = () => {
        var code = javascriptGenerator.workspaceToCode(
          workspase
        );
        console.log(code);
    }

    useEffect(() => {
        var options = props.obj;
        Blockly.setLocale(Ja)
        Blockly.setLocale(CustomJa)
        workspase = Blockly.inject( "blocklyDiv", options);
            // 最初に表示させたいブロック！！！！
        if (options.startBlocks) {
            Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(options.startBlocks), workspase)
        }
        // Add the disableOrphans event handler. This is not done automatically by
        // the plugin and should be handled by your application.
        workspase.addChangeListener(Blockly.Events.disableOrphans);

        // The plugin must be initialized before it has any effect.
        const disableTopBlocksPlugin = new DisableTopBlocks();
        disableTopBlocksPlugin.init();
    },[]);

    var {workspase} = useContext(CodeContext)

    return (
        <React.Fragment>
            <div id="blocklyDiv" style={{width: "95%", height: "600px"}}></div>
        </React.Fragment>
    );
}