import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardComponent from './CardComponent';
import Grid from '@mui/material/Grid'; // Grid version 1
import {useAuth} from '../../../components/firebase/authContext';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Youtube from '../../../components/youtube/youtube'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const {userData} = useAuth();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Card sx={{ minWidth: 275, maxWidth: 800, width:"95%", minHeight:600, mt:1}}>
        <Box sx={{ width: "100%", bgcolor: "#D9631E" , p: 1}}>
          <Typography align='center' variant="h6" color="white">
            ミッションリスト(入力あり) 
          </Typography>
        </Box>
        <Box sx={{ width: "100%", borderBottom: 1, bgcolor: '#fff'}}>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary"
            textColor="primary" aria-label="basic tabs example">
            <Tab label="ビギナー" style={{ color:value === 0 ? "white" : "blue", backgroundColor: value === 0 ? "#EEAF5E" : "white" }} {...a11yProps(0)} />
            <Tab label="シルバー" style={{ color:value === 1 ? "white" : "blue", backgroundColor: value === 1 ? "#EEAF5E" : "white"}}  {...a11yProps(1)} />
            <Tab label="ゴールド" style={{ color:value === 2 ? "white" : "blue", backgroundColor: value === 2 ? "#EEAF5E" : "white"}}  {...a11yProps(2)} />
          </Tabs>
        </Box>
          <TabPanel value={value} index={0}>
            <Accordion sx={{ minWidth: 275, width:"100%", m:1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>入力あり問題の解き方</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ aspectRatio: '16/9'}}>
                <Youtube videoId="2W-ekkTPoAQ" />
              </AccordionDetails>
            </Accordion>
            <CardComponent link="/bin001" detail='入力と表示' clearFlag={userData?.achievement?.["BIn001"] ?? false} ></CardComponent>
            <CardComponent link="/bin002" detail='入力と計算' clearFlag={userData?.achievement?.["BIn002"] ?? false} ></CardComponent>
            <CardComponent link="/bin003" detail='入力と条件分岐' clearFlag={userData?.achievement?.["BIn003"] ?? false} ></CardComponent>
            <CardComponent link="/bin004" detail='入力と繰り返し' clearFlag={userData?.achievement?.["BIn004"] ?? false} ></CardComponent>
          </TabPanel>
          <TabPanel value={value} index={1}>
            随時追加
          </TabPanel>
          <TabPanel value={value} index={2}>
            随時追加
          </TabPanel>
      </Card>
    </Grid>
  );
}