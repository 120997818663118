/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Define custom blocks.
 * @author abbychau@gmail.com (Abby Chau)
 */

// More on defining blocks:
// https://developers.google.com/blockly/guides/create-custom-blocks/define-blocks

import * as Blockly from 'blockly/core'
import {javascriptGenerator} from 'blockly/javascript';

Blockly.Blocks.start = {
  init: function () {
    this.appendDummyInput()
      .appendField('START')
    this.appendStatementInput('start')
      .setCheck(null)
    this.setColour(330)
    this.setTooltip('')
    this.setHelpUrl('')
  }
}

javascriptGenerator.start = function (block:any) {
  var statementsStart = javascriptGenerator.statementToCode(block, 'start')
  // TODO: Assemble JavaScript into code variable.
  // var code = '...;\n'
  // return 'var resultArray = a.N\n' + statementsStart + '\nreturn resultArray'
  return 'var resultArray = ""\n' + statementsStart + '\nreturn resultArray'
  // return 'this.result = "書き変わったよ！"'
}

Blockly.Blocks.block_print = {
  init: function () {
    this.appendValueInput('Print')
      .setCheck(null)
      .appendField('表示する')
    this.setInputsInline(false)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(120)
    this.setTooltip('')
    this.setHelpUrl('')
  }
}

javascriptGenerator.block_print = function (block: any) {
  var valuePrint = javascriptGenerator.valueToCode(block, 'Print', javascriptGenerator.ORDER_ATOMIC)
  // TODO: Assemble JavaScript into code variable.
  var code = 'resultArray +=' + valuePrint + ' + ' + '"\\n"' + '\n'
  return code
}

Blockly.Blocks.block_inputn = {
  init: function () {
    this.appendDummyInput()
      .appendField('変数')
      .appendField(new Blockly.FieldVariable('N'), 'InputN')
      .appendField('にNの値を入力')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  }
}

javascriptGenerator.block_inputn = function (block:any) {
  // var valueInputn = block.getFieldValue('InputN')
  var valueInputn = javascriptGenerator.nameDB_.getName(block.getFieldValue('InputN'), Blockly.Names.NameType.VARIABLE)
  // TODO: Assemble JavaScript into code variable.
  var code = valueInputn + '= inputarg \n'
  return code
}
