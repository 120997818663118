import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import AppBar from './components/AppBar';
import Home from './pages/home/home';
import MissionRoutes from './pages/missionpages/routes/missionRouts';
import AuthProvider from "./components/firebase/authContext";
import Box from '@mui/material/Box';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfServices from './pages/TermsOfServices';
import Footer from './components/Footer';

export const CodeContext = React.createContext({} as {
  workspase: any
})

export function App() {
  return (
    <AuthProvider>
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfServices />} />
        </Routes>
        <AppBar />
        <Box sx={{m:10}}></Box>
        <CodeContext.Provider value={{ workspase: null }}>

        <MissionRoutes />

       </CodeContext.Provider>
      </BrowserRouter>
       <Footer></Footer>
    </div>
    </AuthProvider>
  );
}