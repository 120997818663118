import Swal from 'sweetalert2'

// 第一引数はルーターへの参照、第二引数は不正解(false)か正解(true)か
export default function SwalFalseFire () {
  Swal.fire({
    title: '不正解です',
    icon: 'warning',
    confirmButtonText: '閉じる',
    allowOutsideClick: false
  })
}
