import React from 'react';
import BlocklyComponent from '../../components/BlocklyComponent';
import Button from '@mui/material/Button';
import { useState, useContext } from 'react';
import {javascriptGenerator} from 'blockly/javascript';
import {CodeContext} from '../../../App';
import Grid from '@mui/material/Grid'; // Grid version 1
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate } from "react-router-dom";
import SwalTrueFire from '../functions/SwalTrueFire';
import SwalFalseFire from '../functions/SwalFalseFire';
import setAchievement from '../functions/setAchievement';
import {useAuth} from '../../../components/firebase/authContext';

export function B002() {
    const {currentUser, userData} = useAuth();
    var options = {
      toolbox:
      `<xml>
      <category name="%{BKY_MY_INPUT_OUTPUT}" colour="%{BKY_MY_INOUT_HUE}">
        <block type="block_print"></block>
        <block type="start"></block>
      </category>
      <category name="%{BKY_MY_OPERATORS}" colour="%{BKY_MATH_HUE}">
        <block type="math_number">
          <field name="NUM">123</field>
        </block>
      </category>
      <category name="%{BKY_MY_TEXT}" colour="%{BKY_TEXTS_HUE}">
        <block type="text"></block>
      </category>
      <category name="%{BKY_MY_VARIABLES}" custom="VARIABLE" colour="%{BKY_VARIABLES_HUE}">
      </category>
      <sep></sep>
    </xml>`,
    startBlocks:
    `<xml>
      <block type="start">
      </block>
    </xml>`
    }
    const makeResult = () => {
      var code = javascriptGenerator.workspaceToCode(workspase);
  
      var result = ""
      result = new Function(code)();
      console.log("結果" + result);
      setResult(result)
      return result;
    }
  
    const judgeResult = () => {
      var result = makeResult()
      var answer = "こんにちは\n"
      if (result === answer){
        console.log("正解")
        SwalTrueFire(navigate);
        setAchievement(currentUser, userData, "B002")
      }else{
        console.log("不正解")
        SwalFalseFire();
      }
    }
  
    var {workspase} = useContext(CodeContext)
    const [result, setResult] = useState("ここに実行結果が表示されます")
    const navigate = useNavigate();
  
    return (
        <div>
          <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body1" align='left' sx={{ mt:1, ml:5 }}><Link to={"/home"}>ミッション一覧へ戻る </Link> ※プログラムは保存されません。</Typography>
            <Grid container direction="column" alignItems="center">
              <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                  <CardContent>
                    <Typography variant="h6" align="left" gutterBottom sx={{ color: 'primary.main' }} >
                      問題
                    </Typography>
                    <Typography variant="body1" align="left">
                      実行結果に "こんにちは" を表示しよう。（" " は含まない）
                    </Typography>
                  </CardContent>
                  <Grid container justifyContent="center">
                    <Accordion sx={{ minWidth: 275, width: "99%"}}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>問題詳細</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                              <CardContent>
                                <Typography variant="h6" align="left" gutterBottom >
                                  入力
                                </Typography>
                                <Typography variant="body1" align="left">
                                  なし
                                </Typography>
                              </CardContent>
                          </Card>
                          <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                              <CardContent>
                                <Typography variant="h6" align="left" gutterBottom >
                                  出力例
                                </Typography>
                                <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                                    <CardContent>
                                      <Typography variant="body1" align="left">
                                       こんにちは
                                      </Typography>
                                    </CardContent>
                                </Card>
                              </CardContent>
                          </Card>
                        </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Card>
                <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}}>
                  <CardContent>
                    <Typography variant="body1" align = "left" sx={{ mb: 1 }} >
                    <Button onClick={makeResult} variant="contained" size="large">実行</Button>
                    </Typography>
                      <Card sx={{ minWidth: 275 }} >
                        <CardContent>
                          <Typography variant="body1" align = "left">
                            {result}
                          </Typography>
                        </CardContent>
                      </Card>
                  </CardContent>
                  <CardActions>
                    <Button onClick={judgeResult} variant="contained" size="large" sx={{ backgroundColor: '#e65100',  "&:hover": { backgroundColor: "#d54000" } }} >ジャッジ</Button>
                  </CardActions>
                </Card>
              <BlocklyComponent obj={options}></BlocklyComponent>
            </Grid>
          </Box>
        </div>
    );
  }