import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

const RootBox = styled(Box)({
  backgroundColor: '#1976d2',
  color: '#fff',
  paddingTop: '8px',
  paddingBottom: '10px',
});

const FooterContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const FooterLink = styled(Link)({
  marginLeft: '16px',
  color: '#fff',
});

const Footer: React.FC = () => {
  return (
    <Box sx={{ bgcolor: "#1976d2", display: 'flex'}}>
      <Container maxWidth="sm">
        <Typography variant="subtitle1" color="white" sx={{ fontSize: 10 ,m:2 }}>
          <Link href="/terms-of-service" color="white" underline="hover">
            利用規約
          </Link>
          <Link href="/privacy-policy" color="white" underline="hover" sx={{ ml: 2 }}>
            プライバシーポリシー
          </Link>
          　　&copy; {new Date().getFullYear()}
          プログラミング教室Willow
        </Typography>
      </Container>
    </Box>
    
  );
};

export default Footer;