import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { green } from '@mui/material/colors';


interface CardProps {
  detail: string;  
  link: string;
  clearFlag: boolean;  
}

export default function CardComponent(props: CardProps) {
    return(
      <Card sx={{ minWidth: 275, width:"100%", m:1 }}>
      <CardContent>
        <Typography align='left' variant="h6" color="text.secondary">
          {props.detail}
        </Typography>
        <Typography align='left' variant="h6" color="text.secondary">
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            component={Link}
            to={props.link}
          >
            チャレンジ
          </Button>
          {props.clearFlag ? (
            <CheckCircleOutlineIcon sx={{ mt:1, ml:3, fontSize: 40, color: "green" }} />
          ) : null}
        </Box>
        </Typography>
      </CardContent>
      
    </Card>
    );

}