import { db } from "../../../components/firebase/firebase";
import { collection, doc, updateDoc } from "firebase/firestore";
import {useAuth} from '../../../components/firebase/authContext';

const  setAchievement  = async (user:any, userData:any, mondai:string) => {
  
  const docRef = collection(db, "users");
  const achievement = userData.achievement || {}; 
  await updateDoc(doc(docRef, user.uid), {
    achievement:{
      ...achievement,
      [mondai]: true
    }
  });
  userData.achievement = {      
    ...achievement,
    [mondai]: true
  };
}

export default setAchievement;