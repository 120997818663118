import * as React from 'react';
import { Routes, Route} from "react-router-dom";
import { B001 } from '../BeginnerRank/B001';
import { B002 } from '../BeginnerRank/B002';
import { B003 } from '../BeginnerRank/B003';
import { B004 } from '../BeginnerRank/B004';
import { BIn001 } from '../BeginnerRank/BIn001';
import { BIn002 } from '../BeginnerRank/BIn002';
import { BIn003 } from '../BeginnerRank/BIn003';
import { BIn004 } from '../BeginnerRank/BIn004';
import { Br001 } from '../BronzeRank/Br001';
import { S001 } from '../SilverRank/S001';
import { G001 } from '../GoldRank/G001';

export default function MondaiRoutes() {
  return (
    <Routes>
      <Route path="/b001" element={<B001 />} />
      <Route path="/b002" element={<B002 />} />
      <Route path="/b003" element={<B003 />} />
      <Route path="/b004" element={<B004 />} />
      <Route path="/bin001" element={<BIn001 />} />
      <Route path="/bin002" element={<BIn002 />} />
      <Route path="/bin003" element={<BIn003 />} />
      <Route path="/bin004" element={<BIn004 />} />
      <Route path="/br001" element={<Br001 />} />
      <Route path="/s001" element={<S001 />} />
      <Route path="/g001" element={<G001 />} />
    </Routes>
  );
}