import React from 'react';
import MissionTabList from './components/MissionTabList'
import MissionTabList2 from './components/MissionTabList2'
import Grid from '@mui/material/Grid'; // Grid version 1
import Box from '@mui/material/Box';
import AdCard2 from '../../components/AdComponent/adCard2'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Youtube from '../../components/youtube/youtube'
import Typography from '@mui/material/Typography';

function App() {
    return (
        <div>
            <Grid container direction="column" alignItems="center">
                <Box sx={{ minWidth: 275, maxWidth: 800, height:300, width:"95%", bgcolor: "#2A4988", mt: 10}}>
                    <AdCard2 />
                </Box>
                <Accordion sx={{ minWidth: 275, maxWidth: 800, width:"95%", bgcolor: "#D9631E", mt:3, color: 'white' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>遊び方</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ aspectRatio: '16/9'}}>
                        ※現在の画面と異なる部分があります。
                        <Youtube videoId="CF9MAMoupZc" />
                    </AccordionDetails>
                </Accordion>
                <MissionTabList></MissionTabList>
                <Box sx={{ minWidth: 275, maxWidth: 800, height:300, width:"95%", bgcolor: "#2A4988"}}>
                    <AdCard2 />
                </Box>
                <MissionTabList2></MissionTabList2>
                {/* <a href="https://www.amazon.co.jp/10%E6%89%8D%E3%81%8B%E3%82%89%E3%81%AF%E3%81%98%E3%82%81%E3%82%8B%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0-Scratch%E3%81%A7%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%92%E3%81%A4%E3%81%8F%E3%81%A3%E3%81%A6%E6%A5%BD%E3%81%97%E3%81%8F%E5%AD%A6%E3%81%BC%E3%81%86%E3%80%90Scratch-3%E5%AF%BE%E5%BF%9C%E3%80%91-%E5%A4%A7%E8%A7%92-%E8%8C%82%E4%B9%8B/dp/4774198161?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&crid=4WPI88J8ZWMK&keywords=%E5%B0%8F%E5%AD%A6%E7%94%9F%E3%81%8B%E3%82%89%E3%81%AF%E3%81%98%E3%82%81%E3%82%8B+%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0&qid=1681115851&sprefix=%E5%B0%8F%E5%AD%A6%E7%94%9F%E3%81%8B%E3%82%89%E3%81%AF%E3%81%98%E3%82%81%E3%82%8B+%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%2Caps%2C178&sr=8-15&linkCode=li3&tag=yanagiyud-22&linkId=9180f339510e726d86bbd59327677527&language=ja_JP&ref_=as_li_ss_il" target="_blank">
                <img src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=4774198161&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=yanagiyud-22&language=ja_JP" /></a>
                <img
                    src="https://ir-jp.amazon-adsystem.com/e/ir?t=yanagiyud-22&language=ja_JP&l=li3&o=9&a=4774198161"
                    width="1"
                    height="1"
                    alt=""
                style={{border: "none !important", margin: "0px !important"}}/> */}

            </Grid>

        </div>
    );
}

export default App;