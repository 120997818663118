import Swal from 'sweetalert2'

// 第一引数はルーターへの参照、第二引数は不正解(false)か正解(true)か
export default function SwalTrueFire (navigate: any) {
    Swal.fire({
      title: '正解です',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: '閉じる',
      cancelButtonText: 'ミッション一覧へ',
      allowOutsideClick: false
    }).then(function (result) {
      console.log(result)
      if (result.isConfirmed === false) {
        navigate('/home')
      }
    })
}
