import React from 'react';
import BlocklyComponent from '../../components/BlocklyComponent';
import Button from '@mui/material/Button';
import { useState, useContext } from 'react';
import {javascriptGenerator} from 'blockly/javascript';
import {CodeContext} from '../../../App';
import Grid from '@mui/material/Grid'; // Grid version 1
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate } from "react-router-dom";
import SwalTrueFire from '../functions/SwalTrueFire';
import SwalFalseFire from '../functions/SwalFalseFire';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import setAchievement from '../functions/setAchievement';
import {useAuth} from '../../../components/firebase/authContext';


export function BIn004() {
  const {currentUser, userData} = useAuth();
  var options = {
    toolbox:
    `<xml>
      <category name="%{BKY_MY_INPUT_OUTPUT}" colour="%{BKY_MY_INOUT_HUE}">
        <block type="block_print"></block>
        <block type="block_inputn"></block>
        <block type="start"></block>
      </category>
      <category name="%{BKY_MY_LOGIC}" colour="%{BKY_LOGIC_HUE}">
        <block type="controls_if"></block>
        <block type="controls_ifelse"></block>
        <block type="logic_compare"></block>
        <block type="logic_operation"></block>
        <block type="logic_negate"></block>
      </category>
      <category name="%{BKY_MY_LOOPS}" colour="%{BKY_LOOPS_HUE}">
        <block type="controls_repeat_ext">
          <value name="TIMES">
            <block type="math_number">
              <field name="NUM">10</field>
            </block>
          </value>
        </block>
        <block type="controls_whileUntil"></block>
        <block type="controls_for"></block>
      </category>
      <category name="%{BKY_MY_OPERATORS}" colour="%{BKY_MATH_HUE}">
        <block type="math_number">
          <field name="NUM">123</field>
        </block>
        <block type="math_arithmetic"></block>
        <block type="math_single"></block>
        <block type="math_trig"></block>
        <block type="math_constant"></block>
        <block type="math_number_property"></block>
        <block type="math_round"></block>
        <block type="math_modulo"></block>
        <block type="math_random_int"></block>
        <block type="math_random_float"></block>
      </category>
      <category name="%{BKY_MY_TEXT}" colour="%{BKY_TEXTS_HUE}">
        <block type="text"></block>
      </category>
      <category name="%{BKY_MY_VARIABLES}" custom="VARIABLE" colour="%{BKY_VARIABLES_HUE}">
      </category>
      <sep></sep>
    </xml>`,
    startBlocks:
    `<xml>
      <block type="start">
        <statement name="start">
          <block type="block_inputn"></block>
        </statement>
      </block>
    </xml>`
  }
  const makeResultExam = () => {
    var code = javascriptGenerator.workspaceToCode(workspase);

    var inputarg = input;
    var result = ""
    code = 'var inputarg = ' + inputarg + ';\n' + code
    result = new Function(code)();
    console.log("結果" + result);
    setResult(result)
    return result;
  }
  const makeResult = (input : any) => {
    var code = javascriptGenerator.workspaceToCode(workspase);

    var inputarg = input
    var result = ""
    code = 'var inputarg = ' + inputarg + ';\n' + code
    result = new Function(code)();
    console.log("結果" + result);
    setResult(result)
    return result;
  }

  const judgeResult = () => {
    var judgeResult = [] //入力毎の正誤を表示するための結果を格納
    var answers = ['1\n2\n3\n4\n5\n', '1\n2\n3\n', '1\n']
    var inputs = [5, 3, 1]
    var count = 0;
    for(var i = 0; i < inputs.length; i++){
      var r = makeResult(inputs[i])
      if (answers[i] === r) {
        judgeResult.push('・入力例' + (i + 1) + '：正解')
        count++
      } else {
        judgeResult.push('・入力例' + (i + 1) + '：不正解')
      }
    }
    setJudgeResultList(judgeResult);
    if (count  === answers.length){
      console.log("正解")
      SwalTrueFire(navigate);
      setAchievement(currentUser, userData, "BIn004")
    }else{
      console.log("不正解")
      SwalFalseFire();
    }
  }

  var {workspase} = useContext(CodeContext)
  const [result, setResult] = useState("ここに実行結果が表示されます")
  const [input, setInput] = useState("")
  const handleChange = (event: SelectChangeEvent) => {
    setInput(event.target.value);
  };
  const [judgeResultList, setJudgeResultList] = useState([""])
  const navigate = useNavigate();
  const inputList = [5, 3, 1];

  return (
      <div>
        <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body1" align='left' sx={{ mt:1, ml:5 }}><Link to={"/home"}>ミッション一覧へ戻る </Link> ※プログラムは保存されません。</Typography>
          <Grid container direction="column" alignItems="center">
            <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                <CardContent>
                  <Typography variant="h6" align="left" gutterBottom sx={{ color: 'primary.main' }} >
                    問題
                  </Typography>
                  <Typography variant="body1" align="left">
                    入力Nを受け取り、１～Nまでの値を順に表示してください。
                  </Typography>
                </CardContent>
                <Grid container justifyContent="center">
                  <Accordion sx={{ minWidth: 275, width: "99%"}} defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>問題詳細</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                            <CardContent>
                              <Typography variant="h6" align="left" gutterBottom >
                                入力
                              </Typography>
                              <Typography variant="body1" align="left" gutterBottom >
                                N<br/>
                                ・0&#8804;N&#8804;100<br/>
                                ・Nは整数
                              </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                            <CardContent>
                              <Typography variant="h6" align="left" gutterBottom >
                                出力例
                              </Typography>
                              <Typography variant="body1" align="left">
                                ・例１：入力が5のとき
                              </Typography>
                              <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                                  <CardContent>
                                    <Typography variant="body1" align="left">
                                      1<br/>2<br/>3<br/>4<br/>5<br/>
                                    </Typography>
                                  </CardContent>
                              </Card>
                              <Typography variant="body1" align="left">
                                ・例2：入力が3のとき
                              </Typography>
                              <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                                  <CardContent>
                                    <Typography variant="body1" align="left">
                                    1<br/>2<br/>3<br/>
                                    </Typography>
                                  </CardContent>
                              </Card>
                              <Typography variant="body1" align="left">
                                ・例3：入力が1のとき
                              </Typography>
                              <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}} >
                                  <CardContent>
                                    <Typography variant="body1" align="left">
                                      1
                                    </Typography>
                                  </CardContent>
                              </Card>
                            </CardContent>
                        </Card>
                      </AccordionDetails>
                  </Accordion>
                </Grid>
              </Card>
              <Card sx={{ minWidth: 275, width: "95%", m: 1 , p:1}}>
                <CardContent>
                  <Typography variant="body1" align = "left" sx={{ mb: 1 }} >
                  <Button onClick={makeResultExam} variant="contained" size="large">実行</Button>
                  <FormControl sx={{ ml: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">入力</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={input}
                      onChange={handleChange}
                    >
                      <MenuItem value={inputList[0]}>{inputList[0]}</MenuItem>
                      <MenuItem value={inputList[1]}>{inputList[1]}</MenuItem>
                      <MenuItem value={inputList[2]}>{inputList[2]}</MenuItem>
                    </Select>
                  </FormControl>
                  </Typography>
                    <Card sx={{ minWidth: 275 }} >
                      <CardContent>
                        <Typography style={{ whiteSpace: 'pre-line' }} variant="body1" align = "left">
                          {result}
                        </Typography>
                      </CardContent>
                    </Card>
                </CardContent>
                <CardActions>
                  <Button onClick={judgeResult} variant="contained" size="large" sx={{ backgroundColor: '#e65100'}} >ジャッジ</Button>
                </CardActions>
                <Typography variant="body1" align="left">
                  {judgeResultList[0]}
                </Typography>
                <Typography variant="body1" align="left">
                  {judgeResultList[1]}
                </Typography>
                <Typography variant="body1" align="left">
                  {judgeResultList[2]}
                </Typography>
              </Card>
            <BlocklyComponent obj={options}></BlocklyComponent>
          </Grid>
        </Box>
      </div>
  );
}