import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged, setPersistence, browserSessionPersistence } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: 'AIzaSyBw50xXp4w8PNlLKLWtYfpPh65J6QljepE',
  authDomain: 'prochalle-74406.firebaseapp.com',
  projectId: 'prochalle-74406',
  storageBucket: 'prochalle-74406.appspot.com',
  messagingSenderId: '565574538951',
  appId: '1:565574538951:web:3ecf39b406dd999a7d10e2',
  measurementId: 'G-JL0S4X8XMP'
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
setPersistence(getAuth(), browserSessionPersistence)
export const db = getFirestore(app);
export const auth = getAuth();